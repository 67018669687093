import React, { Component } from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import TwoColumnContent from '../components/two-column-content'
import CaseStudies from '../components/case-studies'

import he from 'he'

import RenderBlock from '../utils/render-block'

class CaseStudyTemplate extends Component {
  render() {
    let caseStudy = this.props.data.case_study
    let metaDescription = caseStudy.yoast_meta.find(x => x.name === 'description') ? caseStudy.yoast_meta.find(x => x.name === 'description').content : '';
    return (
      <>
        <SEO title={he.decode(caseStudy.yoast_title)} bodyClass={caseStudy.slug} description={metaDescription} />
        <TwoColumnContent {...caseStudy} />
        { caseStudy.acf.post_blocks_case_study && caseStudy.acf.post_blocks_case_study.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
        <div style={{paddingTop: `80px`, paddingBottom: `40px`}}>
          <CaseStudies />
        </div>
      </>
    )
  }
}

export const caseStudyQuery = graphql`
  query ($id: String!) {
    case_study: wordpressWpCaseStudy(id: {eq: $id}) {
      title
      slug
      yoast_title
      yoast_meta {  
       name
       content
       property
      }
      acf {
        subtitle
        content
        problem
        solution
        post_blocks_case_study {
          __typename
          ... on WordPressAcf_banner_image {
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_three_column_content {
            column {
              title
              content
            }
            text_color
            background_color
          }
          ... on WordPressAcf_testimonial {
            testimonial {
              title: post_title
              quote: post_content
              acf {
                name
                logo {
                  localFile {
                    childImageSharp {
                      fixed(width: 400) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_quick_contact {
            left_image {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
            right_title
            right_content
            left_title
            left_content
            right_image {
              localFile {
                childImageSharp {
                  fixed(width: 1000) {
                    src
                  }
                }
              }
            }
            form_title
            download {
              source_url
            }
          }
        }
      }
    }
  }
`

export default CaseStudyTemplate