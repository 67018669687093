import React, { Component } from 'react'
import he from 'he'
import { Link } from 'gatsby'

class TwoColumnContent extends Component {
  render() {
    let data = this.props
    return (
      <section className="ciwv ciwv--columns">
        <div className="ciwv__inner container">
          <div className="ciwv__left">
            <h1 className="ciwv__title">{he.decode(data.acf.subtitle)}</h1>
            <h2 className="ciwv__subtitle">{he.decode(data.title)}</h2>
            <div className="ciwv__content" dangerouslySetInnerHTML={{ __html: data.acf.content }} />
          </div>
          <div className="ciwv__image">
            <div className="ciwv__case-study">
              <div className="ciwv__problem">
                <h4>Problem</h4>
                <div dangerouslySetInnerHTML={{ __html: data.acf.problem }} />
              </div>
              <div className="ciwv__solution">
                <h4>Solution</h4>
                <div dangerouslySetInnerHTML={{ __html: data.acf.solution }} />
              </div>
            </div>
            <div className="slim-contact__button">
              <Link to="/contact">Contact FSC Group</Link>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

TwoColumnContent.defaultProps = {
  data: {},
}

export default TwoColumnContent